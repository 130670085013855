import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { DivBody, DivMain, DivTitle } from "~/components/commonComponents/commonComponents";
import LoadingScreen from "~/components/loadScreen/Loadscreen";
import useApi from "~/service/api";
import dayjs from "dayjs";

export default function R1med() {
    const api = useApi();
    const [loadScreen, setLoadScreen] = useState('none');
    const [idAtendimento, setIdAtendimento] = useState('');
    const [openRegistroEncontrado, setOpenRegistroEncontrado] = useState(false);
    const [openConfirmaExclusao, setOpenConfirmaExclusao] = useState(false);
    const [openDialogInicial, setOpenDialogInicial] = useState(false);
    const [registroEncontrado, setRegistroEncontrado] = useState({});

    async function getAtendimentoR1(){
        try {
            if(idAtendimento == ""){
                toast.warning('Informe o id do atendimento');
                return;
            }
            setLoadScreen('flex');
            const resultApi = await api.get(`/r1med/GetAtendimentoR1ById?idAtendimento=${idAtendimento}`);
            if(resultApi.status == 200){
                setRegistroEncontrado(resultApi.data);
                setOpenRegistroEncontrado(true);
            }
            setLoadScreen('none');
        } catch (error) {
            toast.error('Erro ao buscar atendimento');            
            setLoadScreen('none');
        }
    }
    async function deleteResumoR1(){
        try {
            setLoadScreen('flex');
            const resultDeleteResumo = await api.delete(`/r1med/DeleteAtendimentoR1?idAtendimento=${idAtendimento}`);
            if(resultDeleteResumo.status == 200){
                toast.success('Resumo do atendimento excluído com sucesso');
                setOpenConfirmaExclusao(false);
                setOpenRegistroEncontrado(false)
                setLoadScreen('none');
            }
            setLoadScreen('none');
        } catch (error) {
            toast.error('Erro ao tentar excluir resumo do atendimento')
            console.error(error);
            setLoadScreen('none')
        }
    }

    useEffect(()=>{
        setOpenDialogInicial(true);
    },[])

    return (
    <DivMain>
        <DivTitle>
            <h1>R1med</h1>
        </DivTitle>
        <DivBody style={{ height: 'calc(100% - 60px)' }}>
            <Box display={'flex'} alignItems={'center'} paddingTop={2} >
                <Box flex={1} mr={2}>
                    <TextField label="Id do atendimento" fullWidth value={idAtendimento} size="small" onChange={({target})=>setIdAtendimento(target.value)}/>
                </Box>
                <Button onClick={getAtendimentoR1} variant="contained">Procurar</Button>
            </Box>
        </DivBody>
        <LoadingScreen display={loadScreen} />
        <Dialog open={openRegistroEncontrado} onClose={()=>{setOpenRegistroEncontrado(false)}} PaperProps={{sx:{maxWidth:'unset'}}}>
                <DialogTitle textAlign={'center'}>
                    Registro encontrado    
                </DialogTitle>
                <DialogContent>
                    <Box display={'flex'} flexDirection={'column'}>
                        <Typography><span style={{color:'gray'}}>Id:</span> {registroEncontrado.Id}</Typography>
                        <Typography><span style={{color:'gray'}}>Nome:</span> {registroEncontrado.Nome}</Typography>
                        <Typography><span style={{color:'gray'}}>Descrição:</span> {registroEncontrado.Descricao}</Typography>
                        <Typography><span style={{color:'gray'}}>Data do Registro:</span> {dayjs(registroEncontrado.DataCriacao).format("DD/MM/YYYY HH:mm")}</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button fullWidth onClick={()=>{setOpenRegistroEncontrado(false)}}>Fechar</Button>
                    <Button fullWidth variant="contained" onClick={()=>{setOpenConfirmaExclusao(true)}}>Apagar</Button>
                </DialogActions>
        </Dialog>
        <Dialog open={openConfirmaExclusao} onClose={()=>setOpenConfirmaExclusao(false)}>
            <DialogTitle>Confirmação de exclusão</DialogTitle>
            <DialogContent>
                <Typography paragraph variant="h5" textAlign={'center'}>Atenção!</Typography>
                <Typography paragraph>Você está prestes a executar uma função que <span  style={{fontWeight:700}}>EXCLUIRÁ</span> dados do banco de dados de forma irreversível. </Typography>
                <Typography paragraph fontWeight={700}>Esta ação não pode ser desfeita e resultará na perda permanente das informações selecionadas.</Typography>        	    
                <Typography paragraph>Por favor, confirme que você deseja continuar com esta operação.</Typography>
                <Typography paragraph>Se estiver seguro de que deseja prosseguir, clique em <span style={{fontWeight:700}}>CONFIRMAR</span> para continuar.</Typography>
                <Typography paragraph>IMPORTANTE: Verifique cuidadosamente as informações antes de confirmar a exclusão.</Typography>
            </DialogContent>
                <DialogActions>
                    <Button fullWidth variant="contained" onClick={()=>setOpenConfirmaExclusao(false)}>Cancelar</Button>
                    <Button fullWidth onClick={deleteResumoR1} >Confirmar</Button>
                </DialogActions>
        </Dialog>
        <Dialog open={openDialogInicial} onClose={()=>setOpenDialogInicial(false)}>
            <DialogContent>
                <Box display={'flex'} flexDirection={'column'}  >
                    <Typography>Área em construção,</Typography>
                    <Typography>Por hora somente é poissível remover os resumos gerados por um atendimento.</Typography>
                    <ol style={{listStyle: 'decimal',paddingLeft:'26px',margin: '8px 0'}}>
                        <li><Typography>Infome o id do atendimento clique em procurar, o sistema vai trazer as informações do atendimento</Typography></li>
                        <li><Typography>Verifique se é o atendimento que está procurando</Typography></li>
                        <li><Typography>Se estiver tudo certo, cliquem em apagar</Typography></li>
                    </ol>
                    <Typography>Use com sabedoria e responsabilidade </Typography>
                    <Typography>As exclusões são auditadas</Typography>
                    <Typography>Sei quem é você !</Typography>
                </Box>
                <DialogActions>
                    <Button variant="contained" fullWidth onClick={()=>setOpenDialogInicial(false)}>Estou de acordo</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    </DivMain>
  );
}