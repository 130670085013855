import React, { useState, useEffect } from 'react';
import { DivTitle } from '~/pages/financas/StyledFinancas';
import { DivConfigOptions, DivMain, DivSelectionTheme } from './styledPopupConfiguracoes';
import light from '~/styles/themes/light';
import dark from '~/styles/themes/dark';
export default function PopupConfiguracoes({ setTheme }) {
    const [themeSelectedLocal, setThemeSelectedLocal] = useState('');

    function verifyThemeSelected() {
        const themeSelected = localStorage.getItem('theme');
        if (themeSelected) {
            setThemeSelectedLocal(themeSelected);
        } else {
            setThemeSelectedLocal('dark');
        }
    }
    function selectTheme(theme) {
        localStorage.setItem('theme', theme);
        setThemeSelectedLocal(theme);
        if (theme == 'dark') {
            setTheme(dark);
        } else {
            setTheme(light);
        }
    }

    function handleChange(event) {
        const { name, value } = event.target;
    }

    useEffect(() => {
        verifyThemeSelected();
    }, []);

    return (
        <DivMain>
            <DivTitle style={{ textAlign: 'center', width: '100%' }}>
                <h1>Configurações</h1>
            </DivTitle>
            <DivConfigOptions>
                <span>Tema</span>
                <DivSelectionTheme>
                    <input
                        type="radio"
                        name="theme"
                        id="dark"
                        value="dark"
                        checked={themeSelectedLocal == 'dark' ? true : false}
                        onChange={(event) => selectTheme(event.target.value)}
                    />
                    <label htmlFor="dark">Escuro</label>
                    <input
                        type="radio"
                        name="theme"
                        id="light"
                        value="light"
                        checked={themeSelectedLocal == 'light' ? true : false}
                        onChange={(event) => selectTheme(event.target.value)}
                    />
                    <label htmlFor="light">Claro</label>
                </DivSelectionTheme>
            </DivConfigOptions>
        </DivMain>
    );
}
