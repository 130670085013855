import React, { useState, useEffect, useRef } from 'react';
import { Button } from '../commonComponents/commonComponents';
import OwnerScheduleSelect from '~/components/selectCustom/selectCustom';
import {
    DivClientList,
    DivContentActions,
    DivContentScheduleInfo,
    DivPopupScheduleCustom,
    DivSearchClient,
    TextAreaCustom,
} from '../popupSchedule/StylePopupSchedule';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import useApi from '~/service/api';
import Popup from 'reactjs-popup';
import { DivOptionsSelectAgendaName, DivSelectAgendaName, DivSelectionCustom } from '../selectCustom/StyledSelecCustom';
const duration2 = require('dayjs/plugin/duration');
dayjs.extend(duration2);
export default function PopupEditSchedule({ id, close, getAgendamentos, scheduleName }) {
    const [scheduleCustom, setScheduleCustom] = useState({});
    const [clientList, setClientList] = useState([]);
    const [openSelectStatus, setOpenSelectStatus] = useState(false);
    const [openSelectUserName, setOpenSelectUserName] = useState(false);
    const [openSelectTipoAgendamento, setOpenSelectTipoAgendamento] = useState(false);
    const [listControl, setListControl] = useState(false);
    const [openPopupTipoAgendamento, setOpenPopupTipoAgendamento] = useState(false);
    const [userNameSchedule, setUserNameSchedule] = useState(scheduleName);
    const refScheduleInfo = useRef({});
    const refInputSearch = useRef();
    const refDuration = useRef();
    const refTextAreaDescription = useRef();
    const arrayTiposAgendamento = ['Treinamento', 'Demonstração', 'Coleta de banco de dados', 'Restrição de horário', 'Outros'];
    const api = useApi();

    function CalcEnd(time) {
        let hour = time.split(':')[0];
        let minutes = time.split(':')[1];
        let end = dayjs(scheduleCustom.Inicio).add(hour, 'hours').add(minutes, 'minutes').format('YYYY-MM-DDTHH:mm');
        setScheduleCustom({ ...scheduleCustom, Final: end });
    }

    function setInfoSchedule(string, field) {
        refScheduleInfo.current[field] = string;
    }

    function calcDuration(inicio, final) {
        if (inicio && final) {
            const date1 = dayjs(inicio);
            const date2 = dayjs(final);
            const duration = dayjs.duration(date2.diff(date1, 'milliseconds')).format('HH:mm');

            return duration;
        }
    }

    function alteraInicio(date) {
        setScheduleCustom({
            ...scheduleCustom,
            Inicio: date,
            Final: dayjs(date)
                .add(refDuration.current.value.split(':')[0], 'hours')
                .add(refDuration.current.value.split(':')[1], 'minutes')
                .format('YYYY-MM-DDTHH:mm'),
        });
    }

    async function getSchedule(id) {
        try {
            const data = await api.get(`/agenda/GetAgendamentoById?id=${id}`);
            if (data.status === 200) {
                const duration = calcDuration(data.data[0].Inicio, data.data[0].Final);
                setScheduleCustom({ ...data.data[0], Id_do_Agendamento: id, duration: duration });
            }
        } catch (error) {
            console.log(error);
            toast.error('Erro ao carregar agendamentos');
        }
    }

    async function loadClientList(client_name) {
        try {
            const data = await api.get(`/cliente/GetListClients?search=${client_name}`);
            if (data.status === 200 && Array.isArray(data.data)) {
                setClientList(data.data);
            }
        } catch (error) {}
    }

    const Horafinal = ({ date }) => {
        return <span>{dayjs(date).format('DD/MM/YYYY HH:mm')}</span>;
    };

    async function editSchedule(obj_agendamento) {
        try {
            if (
                !obj_agendamento.Tipo_Agendamento ||
                obj_agendamento.Tipo_Agendamento == '' ||
                obj_agendamento.Tipo_Agendamento == '/'
            ) {
                toast.warning('Informe o tipo de agendamento, por favor.');
                return;
            }

            const info_update = {
                Final: dayjs(obj_agendamento.Final).format('YYYY-MM-DDTHH:mm'),
                Inicio: dayjs(obj_agendamento.Inicio).format('YYYY-MM-DDTHH:mm'),
                Descricao: obj_agendamento.Descricao,
                Tipo_Agendamento: obj_agendamento.Tipo_Agendamento,
                Status: obj_agendamento?.Status === 'Agendado' || obj_agendamento?.Status === 2 ? 2 : 1,
                Id_do_Agendamento: obj_agendamento.Id_do_Agendamento,
                Id_do_Usuario: getIdUser(userNameSchedule),
                Id_da_Venda: obj_agendamento.Id_da_Venda || '',
                Id_Cliente: obj_agendamento.Id_Cliente || '',
                Historico_Edicao: dayjs(new Date()).format('YYYY-MM-DDTHH:mm'),
            };
            const data = await api.put(`/agenda/UpdateAgendamento`, info_update);
            // const data = { data: 'Success', status: 200 }
            if (data.data === 'Success' && data.status === 200) {
                toast.success('Editado com sucesso.');
                getAgendamentos(info_update.Inicio, info_update.Final);
                close(false);
            } else {
                toast.warning('Não foi possível salvar o agendamento.');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível realizar a edição do agendamento.');
        }
    }

    function getIdUser(userName) {
        try {
            if (userName === 'Treinamentos') {
                return 42;
            } else if (userName === 'Rodrigo') {
                return 32;
            } else if (userName) {
                return 54;
            } else return -1;
        } catch (error) {
            return -1;
        }
    }

    useEffect(() => {
        getSchedule(id);
    }, []);

    return (
        <DivPopupScheduleCustom>
            <h1>Edição de agendamento</h1>
            <DivContentScheduleInfo>
                <DivSearchClient openList={listControl}>
                    <span>Cliente:</span>
                    <input
                        type="text"
                        placeholder="Informe o nome do cliente"
                        defaultValue={scheduleCustom.Nome}
                        ref={refInputSearch}
                        onKeyPress={(event) => {
                            if (event.currentTarget.value.length > 3) {
                                loadClientList(event.currentTarget.value);
                            }
                        }}
                        onClick={() => {
                            setListControl(!listControl);
                        }}
                    />
                    <DivClientList style={{ width: '100%' }}>
                        {clientList.map((item) => (
                            <div
                                onClick={() => {
                                    refInputSearch.current.value = item.Nome;
                                    setScheduleCustom({
                                        ...scheduleCustom,
                                        Id_Cliente: item.Codigo_do_Cliente,
                                        Id_da_Venda: item.SoftwareId,
                                    });
                                    // setScheduleCustom()
                                    setListControl(false);
                                }}
                            >
                                <span>{item.Nome}</span>
                                <br />
                                <span>{item.Email}</span>
                            </div>
                        ))}
                    </DivClientList>
                </DivSearchClient>
            </DivContentScheduleInfo>
            <DivContentScheduleInfo>
                <span>Usuário:</span>
                <Popup
                    trigger={<DivSelectAgendaName>{scheduleName}</DivSelectAgendaName>}
                    position="bottom center"
                    on={'click'}
                    open={openSelectUserName}
                    arrowStyle={{ color: 'lightgray' }}
                    onOpen={() => setOpenSelectUserName(true)}
                >
                    <DivOptionsSelectAgendaName>
                        <div
                            onClick={() => {
                                setUserNameSchedule('Treinamentos');
                                setOpenSelectUserName(false);
                            }}
                        >
                            Treinamentos
                        </div>
                        <div
                            onClick={() => {
                                setUserNameSchedule('Rodrigo');
                                setOpenSelectUserName(false);
                            }}
                        >
                            Rodrigo
                        </div>
                        <div
                            onClick={() => {
                                setUserNameSchedule('Yuri');
                                setOpenSelectUserName(false);
                            }}
                        >
                            Yuri
                        </div>
                    </DivOptionsSelectAgendaName>
                </Popup>
            </DivContentScheduleInfo>
            <DivContentScheduleInfo>
                <span>Status:</span>
                <Popup
                    trigger={<DivSelectAgendaName>{scheduleCustom.Status === 1 ? 'Cancelado' : 'Agendado'}</DivSelectAgendaName>}
                    position="bottom center"
                    on={'click'}
                    open={openSelectStatus}
                    arrowStyle={{ color: 'lightgray' }}
                    onOpen={() => setOpenSelectStatus(true)}
                >
                    <DivOptionsSelectAgendaName>
                        <div
                            onClick={() => {
                                setScheduleCustom({ ...scheduleCustom, Status: 2 });
                                setOpenSelectStatus(false);
                            }}
                        >
                            Agendado
                        </div>
                        <div
                            onClick={() => {
                                setScheduleCustom({ ...scheduleCustom, Status: 1 });
                                setOpenSelectStatus(false);
                            }}
                        >
                            Cancelado
                        </div>
                    </DivOptionsSelectAgendaName>
                </Popup>
            </DivContentScheduleInfo>
            <DivContentScheduleInfo>
                <span>Tipo:</span>
                <Popup
                    trigger={
                        <DivSelectAgendaName>
                            {scheduleCustom.Tipo_Agendamento === '/'
                                ? 'Selecione o tipo de agendamento'
                                : scheduleCustom.Tipo_Agendamento}
                        </DivSelectAgendaName>
                    }
                    position="bottom center"
                    on={'click'}
                    open={openSelectTipoAgendamento}
                    arrowStyle={{ color: 'lightgray' }}
                    onOpen={() => setOpenSelectTipoAgendamento(true)}
                >
                    <DivOptionsSelectAgendaName>
                        {arrayTiposAgendamento.map((item, index) => {
                            return (
                                <div
                                    key={item}
                                    onClick={() => {
                                        setScheduleCustom({ ...scheduleCustom, Tipo_Agendamento: item });
                                        setOpenSelectTipoAgendamento(false);
                                    }}
                                >
                                    {item}
                                </div>
                            );
                        })}
                    </DivOptionsSelectAgendaName>
                </Popup>
            </DivContentScheduleInfo>
            <DivContentScheduleInfo>
                <span>Inicio: </span>
                <input
                    type="datetime-local"
                    value={scheduleCustom.Inicio}
                    onChange={(event) => {
                        alteraInicio(event.currentTarget.value);
                    }}
                />
                <span style={{ margin: '0 8px' }}>Duração: </span>
                <input
                    ref={refDuration}
                    type="time"
                    defaultValue={scheduleCustom.duration}
                    onChange={(event) => CalcEnd(event.currentTarget.value)}
                    style={{ maxWidth: 'fit-content' }}
                />
            </DivContentScheduleInfo>
            <DivContentScheduleInfo>
                <span>
                    Agendamento será feito das <strong>{dayjs(scheduleCustom?.Inicio).format('DD/MM/YYYY HH:mm')}</strong> às{' '}
                    <strong>
                        <Horafinal date={scheduleCustom.Final} />
                    </strong>
                </span>
            </DivContentScheduleInfo>
            <DivContentScheduleInfo>
                <span>Descrição:</span>
                <TextAreaCustom
                    defaultValue={scheduleCustom.Descricao}
                    ref={refTextAreaDescription}
                    onChange={(event) => setScheduleCustom({ ...scheduleCustom, Descricao: event?.currentTarget.value })}
                />
            </DivContentScheduleInfo>
            <DivContentActions>
                <Button onClick={() => close(false)} style={{ background: '#8e0000' }}>
                    Cancelar
                </Button>
                <Button onClick={() => editSchedule(scheduleCustom)} style={{ background: '#115213' }}>
                    Salvar Edição
                </Button>
            </DivContentActions>
        </DivPopupScheduleCustom>
    );
}
