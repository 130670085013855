import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { MdSearch } from 'react-icons/md';
import {
    Container,
    Title,
    Article,
    Busca,
    ContainerBusca,
    ContainerList,
    Table,
    ScoreAtividade,
    DivInfoAtividade,
    MobileTable,
    DivMobile,
    DivButtonNovoPaciente,
    FormNovoCliente,
    DivPopupNovoCliente,
    DivTituloPopupNovoCliente,
    DivBodyDadosCliente,
    DivDadosContato,
    DivCampo,
    SpanNomeCampos,
    DivDadosEndereco,
    TableRowMui,
} from './stylesClientes';
import ContentLoader from '../../components/loader/Loader';
import 'dayjs/locale/pt-br';
import { toast } from 'react-toastify';
import useApi from '~/service/api';
import SignalIcon from '~/components/signalIcon/SignalIcon';
import Popup from 'reactjs-popup';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import { Button, Input } from '~/components/commonComponents/commonComponents';
import PopupDefault from '~/components/popupDefault/PopupDefault';
import { TextAreaObservacaoCliente } from '~/components/popupEditFicha/styledPopupEditFicha';
import { useTheme } from 'styled-components';
import { TableContainer, Paper, Table as TableMui, TableHead, TableRow, TableCell, TableBody, TextField, FormControl, InputLabel, Select, MenuItem, Dialog, DialogTitle, DialogContent } from '@mui/material'
import TabelaModelo from '~/components/tabelaModelo/tabelaModelo';
import FormCadastroCliente from '~/components/formCadastroCliente/formCadastroCliente';
import PopupNovoTrial from './popupNovoTrial';
import { IconAiOutlineCloseCircle } from '~/components/boletoEdit/StyledBoletoEdit';
import { AiOutlineClose } from 'react-icons/ai';

function Clientes() {
    const api = useApi();
    const history = useHistory();
    const [clientes, setClientes] = useState([]);
    const [iniciando, setIniciando] = useState(true);
    const [loadScreen, setLoadScreen] = useState('none');
    const [clienteInfo, setClienteInfo] = useState({
        Nome: '',
        Email: '',
        DDD: '',
        Telefone: '',
        DDD2: '',
        Celular: '',
        Cgc: '',
        Endereco: '',
        Numero: '',
        Complemento: '',
        Bairro: '',
        Cidade: '',
        Estado: '',
        Cep: '',
        CodigoMunicipio: '',
        Observacoes: '',
    });
    const [openPoupNovoCliente, setOpenPoupNovoCliente] = useState(false);
    const [openNovoTrial, setOpenNovoTrial] = useState(false);
    const [valueFiltro, setValueFiltro] = useState('todos')
    const theme = useTheme();
    const RefForm = useRef();
    
    useEffect(() => {
        init();
    }, []);
    

    async function init(params) {
        try {
            setLoadScreen('flex');
            const ListaClientes = await getClientes(params || '');
            if (ListaClientes.length == 0) {
                toast.warning('Não foi encontrado nenhum cliente.');
                setLoadScreen('none');
            }            
            if (!ListaClientes) throw '';
            const listasClienteRenomeandoContrato = ListaClientes.map(cliente =>{
                if(String(cliente.Status).toLowerCase() == 'full' ){
                    cliente.Status = 'Enterprise'
                }else if(String(cliente.Status).toLowerCase() == 'individual' ){
                    cliente.Status = 'Starter'
                }else if(String(cliente.Status).toLowerCase() == 'small' ){
                    cliente.Status = 'Business'
                }
                return cliente
            })
            setClientes(listasClienteRenomeandoContrato);
            if (iniciando) setIniciando(false);
            setLoadScreen('none');
        } catch (error) {
            console.log(error);
            setLoadScreen('none');
        }
    }

    async function getClientes(busca) {
        return new Promise(async (resolve) => {
            try {
                let { data: clientes } = await api.get(`/cliente/GetListClients?search=${busca}&filter=${valueFiltro}`);
                resolve(clientes);
            } catch (error) {
                console.log(error);
                resolve(false);
            }
        });
    }

    function selecionaCliente(cliente) {
        if (cliente.Codigo_do_Cliente)
            history.push({ pathname: `cliente/${cliente.Codigo_do_Cliente}`, state: cliente.SoftwareId });
    }

    async function searchClient(string_search) {
        if (string_search === '') {
            init();
        } else {
            init(string_search);
        }
    }
    async function cadastrarNovoCliente() {
        try {
            setLoadScreen('flex');
            const responseCadastrarNovoCliente = await api.post(`cliente/InsertNovoCliente`, clienteInfo);
            if (responseCadastrarNovoCliente.status == 200) {
                setOpenPoupNovoCliente(false);
                setClienteInfo({
                    Nome: '',
                    Email: '',
                    DDD: '',
                    Telefone: '',
                    DDD2: '',
                    Celular: '',
                    Cgc: '',
                    Endereco: '',
                    Numero: '',
                    Complemento: '',
                    Bairro: '',
                    Cidade: '',
                    Estado: '',
                    Cep: '',
                    CodigoMunicipio: '',
                    Observacoes: '',
                });
                toast.success('Cliente cadastrado com sucesso.');
                setLoadScreen('none');
            } else {
                toast.error('Não foi possível cadastrar o cliente.');
                setLoadScreen('none');
            }
        } catch (error) {
            toast.error(error || 'Erro ao realizar solicitação');
            setLoadScreen('none');
        }
    }
    const header = ["SoftwID", "Nome", "Email", "Email ADM", "Data", "Pago Até", "Status", "Classificação", "Atividade"];
    
    function retornaValor(key, ObjCompleto) {
        if (key == "SoftwID") {
            return ObjCompleto.SoftwareId
        }
        if (key == "Email ADM") {
            return ObjCompleto.Email_Administrativo
        }
        if (key == "Data") {
            return dayjs(ObjCompleto.Data_do_Cadastro).format('DD/MM/YYYY')
        }
        if (key == "Classificação") {
            return ObjCompleto.Classificacao
        }
        if (key == "Atividade") {
            return <Popup
                trigger={
                    <ScoreAtividade>
                        <SignalIcon score={ObjCompleto.Indice_de_Atividade} />
                    </ScoreAtividade>
                }
                arrow={false}
                position="bottom center"
                on="hover"
            >
                <DivInfoAtividade>{ObjCompleto.Indice_de_Atividade}</DivInfoAtividade>
            </Popup>
        }
        if (key == "Pago Até") {
            return dayjs(ObjCompleto.Pago_ate).format('DD/MM/YYYY')
        }
        return ObjCompleto[key]
    }
    
    const OldForm = ()=>(
        <DivPopupNovoCliente>
            <DivTituloPopupNovoCliente>
                <Title>Informações cadastrais</Title>
            </DivTituloPopupNovoCliente>
            {/*TROCAR PARA FORM PARA VALIDAR OS CAMPOS.*/}
            <FormNovoCliente
                onSubmit={(event) => {
                    event.preventDefault();
                    cadastrarNovoCliente();
                }}
                ref={RefForm}
            >
                <DivCampo>
                    <SpanNomeCampos>Nome do Cliente*</SpanNomeCampos>
                    <Input
                        required
                        placeholder="Informe o nome do cliente."
                        type="text"
                        value={clienteInfo.Nome}
                        onChange={({ target }) => setClienteInfo({ ...clienteInfo, Nome: target.value })}
                    />
                </DivCampo>
                <DivCampo>
                    <SpanNomeCampos>Email de Assinante*</SpanNomeCampos>
                    <Input
                        required
                        placeholder="Informe o email do cliente."
                        type="email"
                        value={clienteInfo.Email}
                        onChange={({ target }) => setClienteInfo({ ...clienteInfo, Email: target.value })}
                    />
                </DivCampo>
                <DivBodyDadosCliente>
                    <DivDadosContato>
                        <div style={{ display: 'flex' }}>
                            <DivCampo style={{ marginRight: '8px' }}>
                                <SpanNomeCampos>DDD </SpanNomeCampos>
                                <Input
                                    type="text"
                                    value={clienteInfo.DDD}
                                    maxLength={2}
                                    style={{ width: '60px' }}
                                    onChange={({ target }) => setClienteInfo({ ...clienteInfo, DDD: target.value })}
                                />
                            </DivCampo>
                            <DivCampo style={{ flex: 1 }}>
                                <SpanNomeCampos>Telefone </SpanNomeCampos>
                                <Input
                                    type="text"
                                    placeholder="Informe o telefone do cliente."
                                    value={clienteInfo.Telefone}
                                    onChange={({ target }) =>
                                        setClienteInfo({ ...clienteInfo, Telefone: target.value })
                                    }
                                />
                            </DivCampo>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <DivCampo style={{ marginRight: '8px' }}>
                                <SpanNomeCampos> DDD2 </SpanNomeCampos>
                                <Input
                                    type="text"
                                    value={clienteInfo.DDD2}
                                    style={{ width: '60px' }}
                                    onChange={({ target }) => setClienteInfo({ ...clienteInfo, DDD2: target.value })}
                                />
                            </DivCampo>
                            <DivCampo style={{ flex: 1 }}>
                                <SpanNomeCampos> Celular* </SpanNomeCampos>
                                <Input
                                    type="text"
                                    placeholder="Informe o celular do cliente."
                                    value={clienteInfo.Celular}
                                    onChange={({ target }) =>
                                        setClienteInfo({ ...clienteInfo, Celular: target.value })
                                    }
                                    required
                                />
                            </DivCampo>
                        </div>
                        <DivCampo>
                            <SpanNomeCampos>Cidade </SpanNomeCampos>
                            <Input
                                type="text"
                                placeholder="Informe a cidade do cliente."
                                onChange={({ target }) => setClienteInfo({ ...clienteInfo, Cidade: target.value })}
                                value={clienteInfo.Cidade}
                            />
                        </DivCampo>
                        <DivCampo>
                            <SpanNomeCampos>CGC*</SpanNomeCampos>
                            <Input
                                required
                                placeholder="Informe o cpf/cnpj do cliente."
                                type="text"
                                value={clienteInfo.Cgc}
                                onChange={({ target }) => setClienteInfo({ ...clienteInfo, Cgc: target.value })}
                            />
                        </DivCampo>
                        <DivCampo>
                            <SpanNomeCampos>Estado </SpanNomeCampos>
                            {/* VAI VIRAR UM SELECT */}
                            <Input
                                type="text"
                                value={clienteInfo.Estado}
                                onChange={({ target }) => setClienteInfo({ ...clienteInfo, Estado: target.value })}
                            />
                        </DivCampo>
                    </DivDadosContato>
                    <DivDadosEndereco id="endereco">
                        <DivCampo>
                            <SpanNomeCampos>CEP </SpanNomeCampos>
                            <Input
                                type="text"
                                placeholder="Informe o cep do cliente."
                                value={clienteInfo.Cep}
                                onChange={({ target }) => setClienteInfo({ ...clienteInfo, Cep: target.value })}
                            />
                        </DivCampo>
                        <DivCampo>
                            <SpanNomeCampos>Endereço </SpanNomeCampos>
                            <Input
                                type="text"
                                placeholder="Informe o endereço do cliente."
                                value={clienteInfo.Endereco}
                                onChange={({ target }) => setClienteInfo({ ...clienteInfo, Endereco: target.value })}
                            />
                        </DivCampo>
                        <DivCampo>
                            <SpanNomeCampos>Nº</SpanNomeCampos>
                            <Input
                                type="text"
                                value={clienteInfo.Numero}
                                onChange={({ target }) => setClienteInfo({ ...clienteInfo, Numero: target.value })}
                            />
                        </DivCampo>
                        <DivCampo>
                            <SpanNomeCampos>Complemento </SpanNomeCampos>
                            <Input
                                type="text"
                                value={clienteInfo.Complemento}
                                onChange={({ target }) =>
                                    setClienteInfo({ ...clienteInfo, Complemento: target.value })
                                }
                            />
                        </DivCampo>
                        <DivCampo>
                            <SpanNomeCampos>Bairro </SpanNomeCampos>
                            <Input
                                placeholder="Informe o bairro do cliente."
                                type="text"
                                value={clienteInfo.Bairro}
                                onChange={({ target }) => setClienteInfo({ ...clienteInfo, Bairro: target.value })}
                            />
                        </DivCampo>
                        <DivCampo>
                            <SpanNomeCampos>Código Município </SpanNomeCampos>
                            <Input
                                placeholder="Informe o código do município do cliente."
                                type="text"
                                value={clienteInfo.CodigoMunicipio}
                                onChange={({ target }) =>
                                    setClienteInfo({ ...clienteInfo, CodigoMunicipio: target.value })
                                }
                            />
                        </DivCampo>
                    </DivDadosEndereco>
                </DivBodyDadosCliente>
                <div style={{ flexDirection: 'column', margin: '8px 0 0 0' }}>
                    <SpanNomeCampos>Observação</SpanNomeCampos>
                    <TextAreaObservacaoCliente
                        value={clienteInfo.Observacoes}
                        onChange={({ target }) => setClienteInfo({ ...clienteInfo, Observacoes: target.value })}
                    />
                </div>
            </FormNovoCliente>
            <div
                style={{
                    display: 'flex',
                    width: '50%',
                    margin: '0 auto',
                    justifyContent: 'space-around',
                    padding: '8px 0',
                }}
            >
                <Button
                    onClick={() => setOpenPoupNovoCliente(false)}
                    style={{ background: theme.colors.colorDelete }}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={() => RefForm.current.requestSubmit()}
                    style={{ background: theme.colors.colorSave }}
                >
                    Salvar
                </Button>
            </div>
        </DivPopupNovoCliente>
    )
    return (
        <Container>
            <Article>
                <ContainerBusca>
                    <TextField      
                        size='small'
                        type='text'
                        placeholder='Busque o cliente pelo nome, email ou softId.'
                        variant='filled'                        
                        sx={{flex:1,mr:1,height:'100%','& .MuiInputBase-root': {
                            height: '100%'}}}
                        inputProps={{style:{boxShadow:'none',padding:'8px 8px 4px 4px'}}}
                        InputProps={{startAdornment:<MdSearch style={{alignSelf: 'self-end',marginBottom:'8px',marginRight:'8px'}}/>}}
                        onBlur={(event) => {
                            return event.currentTarget.value === '' ? searchClient('') : '';
                        }}
                        onKeyPress={(event) => (event.key === 'Enter' ? searchClient(event.target.value) : '')}                      
                    />
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Filtro</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={valueFiltro}
                            label="Filtro"
                            size='small'
                            onChange={(event)=>setValueFiltro(event.target.value)}
                        >
                            <MenuItem value={'todos'}>Todos</MenuItem>
                            <MenuItem value={'email'}>Email</MenuItem>
                            <MenuItem value={'emailadm'}>Email Adm</MenuItem>
                            <MenuItem value={'unificado'}>Login Unificado</MenuItem>
                            <MenuItem value={'celular'}>Celular</MenuItem>
                            <MenuItem value={'cpf'}>CPF/CNPJ</MenuItem>
                            <MenuItem value={'dbid'}>SoftwID</MenuItem>
                        </Select>
                    </FormControl>             
                </ContainerBusca>
                <TabelaModelo
                    clientes={clientes}
                    fnRetornaValor={retornaValor}
                    header={header}
                    callbackDoubleClick={selecionaCliente}
                />
                <DivButtonNovoPaciente>
                    <Button onClick={() => setOpenPoupNovoCliente(true)}>Cadastrar Novo Cliente</Button>
                    <Button onClick={() => setOpenNovoTrial(true)}>Novo Trial</Button>
                </DivButtonNovoPaciente>
            </Article>
            <PopupDefault
                altura={'calc(100% - 40px)'}
                largura={'calc(100% - 40px)'}
                open={openPoupNovoCliente}
                close={() => setOpenPoupNovoCliente(false)}
                children={
                    <FormCadastroCliente close={setOpenPoupNovoCliente}/>                    
                }
            />
            <Dialog open={openNovoTrial} onClose={()=>{setOpenNovoTrial(false)}} PaperProps={{sx:{maxWidth:'unset'}}}>
                <DialogTitle textAlign={'center'}>
                    Formulário novo trial                    
                </DialogTitle>
                <DialogContent>
                    <PopupNovoTrial close={()=>setOpenNovoTrial(false)}/>
                </DialogContent>
            </Dialog>
            <LoadingScreen display={loadScreen} />
        </Container>
    );
}

export default Clientes;


