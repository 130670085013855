import styled from 'styled-components';

export const DivPopupScheduleCustom = styled.div`
    padding: 24px 16px;
    border-radius: 8px;

    h1 {
        font-size: 22px;
        font-family: 'Ubuntu', 'Roboto', sans-serif;
        margin: 0 0 12px 0;
        padding: 0 0 12px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    span {
        cursor: default;
        margin: 0 8px 0 0;
    }
`;
export const DivContentScheduleInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 16px 0;
    min-height: fit-content;
`;
export const DivContentActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 0 16px 0;
    min-height: fit-content;
`
export const TextAreaCustom = styled.textarea`
    display: flex;
    flex: 1;
    resize: none;
    padding: 10px;
    border-radius: 6px;
    background: #1d1d1d;
    color: #fff;
    border: 1px solid #00000000;
    font-size: 16px;
    min-height: 100px;
    :focus {
        border-color: rgba(176, 206, 255, 1);
        box-shadow: 0px 0px 2px 1px rgb(176 206 255);
    }
`;
export const DivClientList = styled.div`
    background: ${(props) => props.theme.colors.background};
    height: 400px;
    overflow: hidden auto;
    padding: 8px 0 0 0;
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 5;

    div {
        cursor: pointer;
        margin: 8px 0;
        padding: 8px 0 8px 4px;
        :hover {
            background: #545454;
            cursor: pointer;
        }
    }
`;

export const DivSearchClient = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    div {
        display: ${(props) => (props.openList ? 'block' : 'none')};
    }
`;
