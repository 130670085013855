import styled from 'styled-components';

export const DivMain = styled.div`
    padding: 20px;
`;
export const DivSelectionTheme = styled.div`
    display: flex;
    min-width: 300px;
    align-items: center;
    margin: 16px 0;
    input {
        width: 25px;
        height: 25px;
        margin: 0 8px 0 0;
        :nth-child(3) {
            margin: 0 8px 0 8px;
        }
    }
`;
export const DivConfigOptions = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 16px 0;
`;
