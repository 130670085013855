import React, { useState, useEffect, useRef } from 'react';
import { Button } from '../commonComponents/commonComponents';
import OwnerScheduleSelect from '~/components/selectCustom/selectCustom';
import {
    DivClientList,
    DivContentActions,
    DivContentScheduleInfo,
    DivPopupScheduleCustom,
    DivSearchClient,
    TextAreaCustom,
} from './StylePopupSchedule';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import useApi from '~/service/api';
import Popup from 'reactjs-popup';
import { DivOptionsSelectAgendaName, DivSelectAgendaName, DivSelectionCustom } from '../selectCustom/StyledSelecCustom';

function PopupSchedule({ end, start, close, getAgendamentos, scheduleName }) {
    const [scheduleCustom, setScheduleCustom] = useState({
        end,
        start,
        description: '',
        tipoAgendamento: 'Selecione o tipo do agendamento',
        tipoTreinamento: 'Selecione o tipo de treinamento',
        status: 2,
        idCliente: 0,
        idUsuario: getIdUser(scheduleName),
    });
    const [clientList, setClientList] = useState([]);
    const [openSelectStatus, setOpenSelectStatus] = useState(false);
    const refInputSearch = useRef();
    const refDuration = useRef();
    const api = useApi();
    const [listControl, setListControl] = useState(false);
    const [openSelectUsuario, setOpenSelectUsuario] = useState(false);
    const [openSelectTipoAgendamento, setOpenSelectTipoAgendamento] = useState(false);
    const [openSelectTipoTreinamento, setOpenSelectTipoTreinamento] = useState(false);
    const arrayTiposAgendamento = ['Treinamento', 'Demonstração', 'Coleta de banco de dados', 'Restrição de horário', 'Outros'];
    const arrayTiposTreinamento = [
        'Completo',
        'Secretária',
        'Médico',
        'Marketing',
        'Financeiro',
        'Medx Social',
        'Retirada de dúvidas',
    ];
    const arrayUsuarios = ['Treinamentos', 'Rodrigo', 'Yuri'];
    function CalcEnd(time) {
        let hour = time.split(':')[0];
        let minutes = time.split(':')[1];
        let end = dayjs(scheduleCustom.start).add(hour, 'hours').add(minutes, 'minutes').format('YYYY-MM-DDTHH:mm');
        setScheduleCustom({ ...scheduleCustom, end: end });
    }
    function getIdUser(userName) {
        try {
            if (userName === 'Treinamentos') {
                return 42;
            } else if (userName === 'Rodrigo') {
                return 32;
            } else if (userName) {
                return 54;
            } else return -1;
        } catch (error) {
            return -1;
        }
    }
    async function loadClientList(client_name) {
        try {
            const data = await api.get(`/cliente/GetListClients?search=${client_name}`);
            if (data.status === 200 && Array.isArray(data.data)) {
                setClientList(data.data);
            }
        } catch (error) { }
    }
    function alteraInicio(date, duration) {
        let hour = duration.split(':')[0];
        let minutes = duration.split(':')[1];
        let end = dayjs(scheduleCustom.start).add(hour, 'hours').add(minutes, 'minutes').format('YYYY-MM-DDTHH:mm');
        setScheduleCustom({ ...scheduleCustom, start: date, end: end });
        // CalcEnd(refDuration.current.value);
    }

    async function saveSchedule() {
        try {
            if (scheduleCustom.description === '') {
                toast.warning('Preencha o campo de descrição');
                return;
            }
            if (
                !scheduleCustom.tipoAgendamento ||
                scheduleCustom.tipoAgendamento === '' ||
                String(scheduleCustom.tipoAgendamento).toLowerCase() === 'selecione o tipo do agendamento'
            ) {
                toast.warning('Informe o tipo de agendamento, por favor.');
                return;
            }
            if (scheduleCustom.tipoTreinamento === 'Selecione o tipo de treinamento' || scheduleCustom.tipoTreinamento === '') {
                toast.warning('Informe o tipo de treinamento, por favor.');
                return;
            }
            if (dayjs(scheduleCustom.start).format('YYYY-MM-DDTHH:mm') > dayjs(scheduleCustom.end).format('YYYY-MM-DDTHH:mm')) {
                toast.warning('Horário inicial maior que o horário final.')
                return;
            }
            let newScheduleObj = {
                Id_do_Usuario: scheduleCustom.idUsuario,
                Inicio: `${dayjs(scheduleCustom.start).format('YYYY-MM-DDTHH:mm')}`,
                Final: `${dayjs(scheduleCustom.end).format('YYYY-MM-DDTHH:mm')}`,
                Descricao: (
                    scheduleCustom.description +
                    ' ' +
                    scheduleCustom.tipoAgendamento +
                    ' ' +
                    scheduleCustom.tipoTreinamento
                )
                    .replace(/undefined/g, '')
                    .trim(),
                Status: scheduleCustom.status,
                Tipo_Agendamento: (scheduleCustom.tipoAgendamento + '/').replace(/undefined/g, '').trim(),
                Id_Cliente: scheduleCustom.idCliente,
                Historico_Edicao: `${dayjs(new Date()).format('YYYY-MM-DDTHH:mm')}`,
            };
            const data = await api.post(`/agenda/InsertAgendamento`, newScheduleObj);
            // const data = { data: 'Success', status: 200 }
            if (data.data === 'Success' && data.status === 200) {
                toast.success('Agendamento feito com sucesso!');
                getAgendamentos(newScheduleObj.Inicio, newScheduleObj.Final);
                close();
            } else {
                toast.warning('Erro ao salvar o agendamento');
            }
        } catch (error) {
            toast.error('Não fio possível realizar o agendamento');
        }
    }
    useEffect(() => {
        loadClientList('');
    }, []);

    return (
        <DivPopupScheduleCustom>
            <h1>Novo Agendamento</h1>
            <DivContentScheduleInfo>
                <DivSearchClient openList={listControl}>
                    <span>Cliente:</span>
                    <input
                        type="text"
                        placeholder="Informe o nome do cliente"
                        ref={refInputSearch}
                        onKeyPress={(event) => {
                            if (event.currentTarget.value.length > 3) {
                                loadClientList(event.currentTarget.value);
                            }
                        }}
                        onClick={() => {
                            setListControl(!listControl);
                        }}
                    />
                    <DivClientList style={{ width: '100%' }} onMouseLeave={() => setListControl(!listControl)}>
                        {clientList.map((item) => (
                            <div
                                onClick={() => {
                                    refInputSearch.current.value = item.Nome;
                                    setScheduleCustom({
                                        ...scheduleCustom,
                                        Id_Cliente: item.Codigo_do_Cliente,
                                        Id_da_Venda: item.SoftwareId,
                                    });
                                    // setScheduleCustom()
                                    setListControl(false);
                                }}
                            >
                                <span>{item.Nome}</span>
                                <br />
                                <span>{item.Email}</span>
                            </div>
                        ))}
                    </DivClientList>
                </DivSearchClient>
            </DivContentScheduleInfo>
            <DivContentScheduleInfo>
                <span>Usuário:</span>
                <Popup
                    trigger={<DivSelectAgendaName>{scheduleName}</DivSelectAgendaName>}
                    position="bottom center"
                    on={'click'}
                    open={openSelectUsuario}
                    arrowStyle={{ color: 'lightgray' }}
                    onOpen={() => setOpenSelectUsuario(true)}
                >
                    <DivOptionsSelectAgendaName>
                        {arrayUsuarios.map((item, index) => {
                            return (
                                <div
                                    key={item}
                                    onClick={() => {
                                        setScheduleCustom({ ...scheduleCustom, idUsuario: getIdUser(item) });
                                        setOpenSelectUsuario(false);
                                    }}
                                >
                                    {item}
                                </div>
                            );
                        })}
                    </DivOptionsSelectAgendaName>
                </Popup>
            </DivContentScheduleInfo>
            <DivContentScheduleInfo>
                <span>Status:</span>
                <Popup
                    trigger={<DivSelectAgendaName>Agendado</DivSelectAgendaName>}
                    position="bottom center"
                    on={'click'}
                    open={openSelectStatus}
                    arrowStyle={{ color: 'lightgray' }}
                    onOpen={() => setOpenSelectStatus(true)}
                >
                    <DivOptionsSelectAgendaName>
                        <div
                            onClick={() => {
                                setScheduleCustom({ ...scheduleCustom, status: 2 });
                                setOpenSelectStatus(false);
                            }}
                        >
                            Agendado
                        </div>
                        <div
                            onClick={() => {
                                setScheduleCustom({ ...scheduleCustom, status: 1 });
                                setOpenSelectStatus(false);
                            }}
                        >
                            Cancelado
                        </div>
                    </DivOptionsSelectAgendaName>
                </Popup>
            </DivContentScheduleInfo>
            <DivContentScheduleInfo>
                <span>Tipo:</span>
                <DivSelectionCustom>
                    <Popup
                        trigger={<DivSelectAgendaName>{scheduleCustom.tipoAgendamento}</DivSelectAgendaName>}
                        position="bottom center"
                        on={'click'}
                        open={openSelectTipoAgendamento}
                        arrowStyle={{ color: 'lightgray' }}
                        onOpen={() => setOpenSelectTipoAgendamento(true)}
                    >
                        <DivOptionsSelectAgendaName>
                            {arrayTiposAgendamento.map((item, index) => {
                                return (
                                    <div
                                        key={item}
                                        onClick={() => {
                                            setScheduleCustom({ ...scheduleCustom, tipoAgendamento: item });
                                            setOpenSelectTipoAgendamento(false);
                                        }}
                                    >
                                        {item}
                                    </div>
                                );
                            })}
                        </DivOptionsSelectAgendaName>
                    </Popup>
                </DivSelectionCustom>
            </DivContentScheduleInfo>
            <DivContentScheduleInfo>
                <span>Tipo de treinamento:</span>
                <DivSelectionCustom>
                    <Popup
                        trigger={<DivSelectAgendaName>{scheduleCustom.tipoTreinamento}</DivSelectAgendaName>}
                        position="bottom center"
                        on={'click'}
                        open={openSelectTipoTreinamento}
                        arrowStyle={{ color: 'lightgray' }}
                        onOpen={() => setOpenSelectTipoTreinamento(true)}
                    >
                        <DivOptionsSelectAgendaName>
                            {arrayTiposTreinamento.map((item, index) => {
                                return (
                                    <div
                                        key={item}
                                        onClick={() => {
                                            setScheduleCustom({ ...scheduleCustom, tipoTreinamento: item });
                                            setOpenSelectTipoTreinamento(false);
                                        }}
                                    >
                                        {item}
                                    </div>
                                );
                            })}
                        </DivOptionsSelectAgendaName>
                    </Popup>
                </DivSelectionCustom>
            </DivContentScheduleInfo>
            <DivContentScheduleInfo>
                <span>Inicio: </span>
                <input
                    type="datetime-local"
                    value={scheduleCustom?.start}
                    onChange={(event) => {
                        alteraInicio(event.currentTarget.value, refDuration.current.value);
                    }}
                    onKeyUp={(event) => {
                        alteraInicio(event.currentTarget.value, refDuration.current.value);
                    }}
                />
                <span style={{ margin: '0 8px' }}>Duração: </span>
                <input
                    ref={refDuration}
                    type="time"
                    defaultValue={'01:30'}
                    onChange={(event) => CalcEnd(event.currentTarget.value)}
                    style={{ maxWidth: 'fit-content' }}
                />
            </DivContentScheduleInfo>
            <DivContentScheduleInfo>
                <span>
                    Agendamento será feito das <strong>{dayjs(scheduleCustom?.start).format('DD/MM/YYYY HH:mm')}</strong> às{' '}
                    <strong>
                        <span>{scheduleCustom?.end ? dayjs(scheduleCustom.end).format('DD/MM/YYYY HH:mm') : ''}</span>
                    </strong>
                </span>
            </DivContentScheduleInfo>
            <DivContentScheduleInfo>
                <span>Descrição:</span>
                <TextAreaCustom
                    defaultValue={scheduleCustom.description}
                    onChange={(event) => setScheduleCustom({ ...scheduleCustom, description: event.currentTarget.value })}
                />
            </DivContentScheduleInfo>
            <DivContentActions>
                <Button onClick={close} style={{ background: '#8e0000' }}>
                    Cancelar
                </Button>
                <Button onClick={saveSchedule} style={{ background: '#115213' }}>
                    Salvar
                </Button>
            </DivContentActions>
        </DivPopupScheduleCustom>
    );
}

export default PopupSchedule;
