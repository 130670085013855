import React, { useRef, useCallback, useState, useEffect, useMemo, memo } from 'react';
import Calendar from '@toast-ui/react-calendar';
import DatePicker from '~/components/dataPicker/DataPicker';
import 'tui-calendar/dist/tui-calendar.css';
import dayjs from 'dayjs';
import Popup from 'reactjs-popup';
import {
    DivContentScheduleInfo,
    DivControll,
    DivControllViewSchedule,
    DivMainAgendaComponent,
    DivPopupScheduleCustom,
    DivSelectDaySchedule,
    DivToday,
    IconBiSearch,
    IconFaArrowCircleDown,
    IconFaGreaterThan,
    IconFaLessThan,
    TextAreaCustom,
} from './StyledAgendaComponente';
import { toast } from 'react-toastify';
import { Button } from '../commonComponents/commonComponents';
import PopupSchedule from '../popupSchedule/PopupSchedule';
import PopupDefault from '../popupDefault/PopupDefault';
import PopupBuscaAgendamento from '../popupBuscaAgendamento/PopupBuscaAgendamento';
import useApi from '~/service/api';
import { MyTheme } from '~/helpers/constates';
import LoadingScreen from '../loadScreen/Loadscreen';
import PopupEditSchedule from '../popupEditSchedule/PopupEditSchedule';
const customParseFormat = require('dayjs/plugin/customParseFormat');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

function AgendaComponente({ view, schedules, setSchedules, getAgendamentos, cal, scheduleName }) {
    const myTheme = MyTheme();
    const [openScheduler, setOpenScheduler] = useState(false);
    const [openPopupScheduleEdit, setOpenPopupScheduleEdit] = useState(false);
    const [openBusca, setOpenBusca] = useState(false);
    const [scheduleCustom, setScheduleCustom] = useState({
        status: '',
        agenda: '',
        tipo: '',
        start: '',
        end: '',
        description: '',
    });

    const calendars = [
        {
            id: '0',
            name: 'Private',
            bgColor: '#ececec',
            borderColor: '#ececec',
        },
    ];
    function getAgendamentosDaySelected() {
        const date = cal.current.calendarInst.getDate();
        getAgendamentos(dayjs(date).format('YYYY-MM-DDTHH:mm'), dayjs(date).format('YYYY-MM-DDTHH:mm'));
    }
    function getWeekDays() {
        try {
            const dateWeekInitial = cal.current.calendarInst.getDateRangeStart();
            const dateWeekFinal = cal.current.calendarInst.getDateRangeEnd();
            getAgendamentos(
                dayjs(dateWeekInitial._date).format('YYYY-MM-DDTHH:mm'),
                dayjs(dateWeekFinal._date).format('YYYY-MM-DDTHH:mm'),
            );
        } catch (error) {
            toast.error('Error ao carregar os dias.');
            console.log(error);
        }
    }
    function changeDayOrWeek() {
        if (view === 'dia') {
            getAgendamentosDaySelected();
        } else {
            getWeekDays();
        }
    }
    const onClickSchedule = useCallback((event) => {
        console.log(event);
        const { calendarId, id, title, start, end, raw } = event.schedule;
        setScheduleCustom({
            end: end._date,
            start: dayjs(start._date).format('YYYY-MM-DDTHH:mm'),
            description: title,
            agenda_name: raw.agenda_name,
            tipoAgendamento: raw.tipoAgendamento,
            status: raw.status,
            edit: true,
            id: id,
        });
        setOpenPopupScheduleEdit(true);
        const el = cal.current.calendarInst.getElement(id, calendarId);
    }, []);
    const onBeforeCreate = useCallback((event) => {
        event.guide.guideElement.style.display = 'none'
        setScheduleCustom({
            end: dayjs(event.start._date).add(150+60, 'minutes').format('YYYY-MM-DDTHH:mm'),
            start: dayjs(event.start._date).add(2, 'hours').format('YYYY-MM-DDTHH:mm'),
            description: '',
        });
        setOpenScheduler(true);
        // setSchedules([...schedules, newSchedule]);
    }, []);
    useEffect(() => {
        changeDayOrWeek();
    }, [view]);

    return (
        <DivMainAgendaComponent>
            <div style={{ display: 'flex', width: '100%', height: '99%', justifyContent: 'space-around' }}>
                <div style={{ height: '100%', width: '99%' }}>
                    <Calendar
                        ref={cal}
                        height="100%"
                        style={{ overflow: 'auto' }}
                        calendars={calendars}
                        disableDblClick={true}
                        disableClick={false}
                        isReadOnly={false}
                        month={{
                            startDayOfWeek: 0,
                        }}                        
                        schedules={schedules.filter((item) => item.raw['status'] === 2 || item.raw['status'] === 0)}
                        scheduleView
                        taskView={false}
                        theme={myTheme}
                        template={{
                            timegridCurrentTime: (item) => {
                                console.log(item)
                                item.hourmarker._date = dayjs(new Date()).format('HH:mm');
                                return item.hourmarker._date;
                            },
                            timegridDisplayPrimaryTime: (time) => {
                                //return time.hour                                
                                if (cal.current.calendarInst.getDate() > new Date('2022-03-14 00:00') && cal.current.calendarInst.getDate() < new Date('2022-10-30 23:59')) {
                                    
                                    return time.hour + 2 + ':' + (time.minutes.length == 2 ? time.minutes : '0' + time.minutes);
                                }                                
                                return time.hour + 2 + ':' + (time.minutes.length == 2 ? time.minutes : '0' + time.minutes);
                            },
                            timegridDisplayTime: (time) => {
                                return time.hour;
                            },
                            timezoneDisplayLabel: (timezoneOffset, displayLabel) => {

                                let gmt, hour, minutes;

                                if (!displayLabel) {
                                    return displayLabel;
                                }
                            },
                        }}
                        timezone={{
                            zones: [
                                {
                                    displayLabel: 'GMT-03:00',
                                    timezoneName: 'America/New_York',
                                    tooltip: 'Brasília',
                                },
                            ],
                        }}
                        useDetailPopup={false}
                        useCreationPopup={false}
                        view={view.replace('dia', 'day').replace('semana', 'week').replace('mês', 'month')} // You can also set the `defaultView` option.
                        week={{
                            showTimezoneCollapseButton: true,
                            timezonesCollapsed: true,
                            hourStart: 5,
                            hourEnd: 20,
                            workweek: true,
                            daynames: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'],
                        }}
                        onClickSchedule={onClickSchedule}
                        onBeforeCreateSchedule={onBeforeCreate}
                        draggable={false}
                    />
                </div>
            </div>
            <PopupDefault
                open={openScheduler}
                close={() => setOpenScheduler(false)}
                children={
                    <PopupSchedule
                        scheduleName={scheduleName}
                        end={scheduleCustom.end}
                        start={scheduleCustom.start}
                        description={scheduleCustom.description}
                        close={() => setOpenScheduler(false)}
                        tipoAgendamento={scheduleCustom.tipo}
                        status={scheduleCustom.status}
                        getAgendamentos={getAgendamentos}
                    />
                }
            />
            <PopupDefault
                open={openPopupScheduleEdit}
                closeOnDocumentClick={false}
                close={() => setOpenPopupScheduleEdit(false)}
                children={
                    <PopupEditSchedule
                        scheduleName={scheduleName}
                        end={scheduleCustom.end}
                        start={scheduleCustom.start}
                        description={scheduleCustom.description}
                        close={setOpenPopupScheduleEdit}
                        tipoAgendamento={scheduleCustom.tipo}
                        status={scheduleCustom.status}
                        id={scheduleCustom.id}
                        getAgendamentos={getAgendamentos}
                    />
                }
            />

            <PopupDefault
                open={openBusca}
                close={() => setOpenBusca(false)}
                children={<PopupBuscaAgendamento openEdit={setOpenPopupScheduleEdit} setDadosAgendamento={setScheduleCustom} />}
            />
        </DivMainAgendaComponent>
    );
}

export default AgendaComponente;
