import styled from 'styled-components';
import { TableRow } from '@mui/material'
export const TableRowMui = styled(TableRow)`
>td{
    color:${p => p.cancelado ? 'red!important' : 'inherit'} ;
    font-style:${p => p.cancelado ? 'italic !important' : 'unset'} ;
}
    :hover{
        color: #1463a6 !important;;
        background:#1463a6;
    }
`
