import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useRouteMatch, useLocation } from 'react-router-dom';

import Login from '~/pages/login/Login';
import NotFound from '~/pages/notFound/NotFound';
import Clientes from '~/pages/clientes/Clientes';
import Cliente from '~/pages/cliente/Cliente';
import Boleto from '~/pages/boleto/Boleto';
import Social from '~/pages/social/Social';
import Prescreva from '~/pages/prescreva/Prescreva';
import Agenda from '~/pages/agenda/Agenda';
import Dashboard from '~/pages/dashboard/Dashboard';
import Envios from '~/pages/envios/Envios';
import Financas from '~/pages/financas/Financas';
import Tecnico from '~/pages/tecnico/Tecnico';
import Docflix from '~/pages/docflix/Docflix';
import Menu from '~/components/menu/Menu';
import UsuarioMedx from '~/pages/usuariosMedx/usuariosMedx';
import Backups from '~/pages/backups/backups';
import UsuariosSistemaInterno from './pages/usuariosSistemaInterno/usuariosSistemaInterno';
import { ContainerAplicativo } from '~/components/commonComponents/commonComponents';
import { isAuthenticated } from '~/service/auth';
import { DivRouter } from 'config/GlobalStyle';
import FinancasBoletosAtrasados from './pages/financasFaturasPendentes/FinancasBoletosAtrasados';
import FinancasNotaFiscal from './pages/financasNotaFiscal/financasNotaFiscal';
import FinancasConciliacao from './pages/financasConciliacao/financasConciliacao';
import ContratosEmRisco from './pages/contratosEmRisco/contratosEmRisco';
import FollowUp from './pages/followUp/followUp';
import Eventos from './pages/eventos/eventos.js';
import GammyxAlunos from './pages/gammyx/alunos/gammyxAlunos';
import GammyxDashboard from './pages/gammyx/gammyxDashboard/gammyxDashboard';
import GammyxAluno from './pages/gammyx/aluno/aluno';
import ClientesHome from './pages/clientes/home/clientesHome';
import GammyxVendas from './pages/gammyx/vendas/gammyxVendas';
import GammyxCursos from './pages/gammyx/cursos/gammyxCursos';
import GammyxAutores from './pages/gammyx/autores/gammyxAutores';
import GammyxCurso from './pages/gammyx/curso/gammyxCurso';
import FinancasIntegracaoPlugNotas from './pages/financasIntegracaoPlugNotas/financasIntegracaoPlugNotas';
import Notificacoes from './pages/eventos/notificacoes/notificacoes';
import { LoginUnificado } from './pages/clientes/loginUnificado/loginUnificado';
import Cancelamentos from './pages/cancelamentos/cancelamentos';
import AssistenteInteligente from './components/assistenteInteligente/assistenteInteligente';
import MensagensSdr from './pages/mensagensSdr/mensagensSdr';
import Vendas from './pages/vendas/vendas';
import BoletosPendentesVendas from './pages/boletosPendentesVendas/boletosPendentesVendas';
import R1med from './pages/r1med/r1med';
export default function App({ setTheme }) {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Login />
                </Route>
                <Route path="/app">
                    <ContainerAplicativo>
                        <Menu setTheme={setTheme} />
                        <Aplicativo />
                        <AssistenteInteligente />
                    </ContainerAplicativo>
                </Route>
                <Route path="/404">
                    <NotFound />
                </Route>
                {/* <Redirect from='*' to='/404' /> */}
            </Switch>
        </Router>
    );
}

function Aplicativo() {
    const match = useRouteMatch();
    const location = useLocation();

    return (
        <DivRouter>
            {/* <MenuPrincipal /> */}
            <Switch location={location}>
                <PrivateRoute exact path={`${match.path}/Clientes`} component={Clientes} />
                {/* <PrivateRoute exact path={`${match.path}/Clientes`} component={ClientesHome} /> */}
                <PrivateRoute exact path={`${match.path}/clientes/contratoemrisco`} component={ContratosEmRisco} />
                <PrivateRoute exact path={`${match.path}/clientes/cancelamentos`} component={Cancelamentos} />
                <PrivateRoute exact path={`${match.path}/clientes/loginunificado`} component={LoginUnificado} />                
                <PrivateRoute path={`${match.path}/Cliente/:id`} component={Cliente} />
                <PrivateRoute path={`${match.path}/Boleto`} component={Boleto} />
                <PrivateRoute path={`${match.path}/Agenda`} component={Agenda} />
                <PrivateRoute path={`${match.path}/Dashboard`} component={Dashboard} />
                <PrivateRoute path={`${match.path}/Envios`} component={Envios} />
                <PrivateRoute exact path={`${match.path}/Financas`} component={Financas} />
                <PrivateRoute path={`${match.path}/Tecnico`} component={Tecnico} />
                <PrivateRoute path={`${match.path}/medxsocial`} component={Social} />
                <PrivateRoute path={`${match.path}/prescreva`} component={Prescreva} />
                <PrivateRoute path={`${match.path}/docflix`} component={Docflix} />
                <PrivateRoute path={`${match.path}/usuariosmedx`} component={UsuarioMedx} />
                <PrivateRoute path={`${match.path}/backups`} component={Backups} />
                <PrivateRoute path={`${match.path}/financas/boletosatrasados`} component={FinancasBoletosAtrasados} />
                <PrivateRoute path={`${match.path}/usuarios`} component={UsuariosSistemaInterno} />
                <PrivateRoute exact path={`${match.path}/financas/notafiscal`} component={FinancasNotaFiscal} />
                <PrivateRoute exact path={`${match.path}/financas/conciliacao`} component={FinancasConciliacao} />
                <PrivateRoute exact path={`${match.path}/financas/integracaoplugnotas`} component={FinancasIntegracaoPlugNotas} />
                <PrivateRoute path={`${match.path}/followupsuporte`} component={FollowUp} />
                <PrivateRoute exact path={`${match.path}/eventos`} component={Eventos} />
                <PrivateRoute path={`${match.path}/eventos/notificacoes`} component={Notificacoes} />
                <PrivateRoute exact path={`${match.path}/gammyx`} component={GammyxDashboard} />
                <PrivateRoute exact path={`${match.path}/gammyx/dashboard`} component={GammyxDashboard} />
                <PrivateRoute exact path={`${match.path}/gammyx/alunos`} component={GammyxAlunos} />
                <PrivateRoute exact path={`${match.path}/gammyx/aluno/:alunoId`} component={GammyxAluno} />
                <PrivateRoute exact path={`${match.path}/gammyx/vendas`} component={GammyxVendas} />
                <PrivateRoute exact path={`${match.path}/gammyx/cursos`} component={GammyxCursos} />
                <PrivateRoute exact path={`${match.path}/gammyx/curso/:cursoId`} component={GammyxCurso} />
                <PrivateRoute exact path={`${match.path}/gammyx/autores`} component={GammyxAutores} />
                <PrivateRoute exact path={`${match.path}/vendas`} component={BoletosPendentesVendas} />
                <PrivateRoute exact path={`${match.path}/vendas/mensagemsdr`} component={MensagensSdr} />
                <PrivateRoute exact path={`${match.path}/r1med`} component={R1med} />
                {/* <Redirect from="*" to="/404" /> */}
            </Switch>
        </DivRouter>
    );
}

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: '/', state: { from: props.location, message: 'Faça o login novamente.' } }} />
            )
        }
    />
);
