import { lighten } from 'polished';
import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';
export default createGlobalStyle`

	*{
		margin:0;
		padding:0;
		outline:0;
		box-sizing:border-box;
	}
	
	html , body , #root{
		height:100%;
		--fontColor: ${(p) => p.theme.colors.text};
	}

	body {
		background-color: ${(p) => p.theme.colors.background};  
		font: 16px Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased !important;
		font-family: 'Roboto','Ubuntu', sans-serif;
		letter-spacing: .6px!important;
		color: var(--fontColor)!important;
		text-rendering: optimizeLegibility;
		-webkit-font-smoothing:antialiased;
	}

	body a{
		text-decoration:none!important;
		color:inherit;
		
	}
	body a:active{
		text-decoration:none!important;
	}

	html, body, div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td,
	article, aside, canvas, details, embed, 
	figure, figcaption, footer, header, hgroup, 
	menu, nav, output, ruby, section, summary,
	time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		vertical-align: baseline;
	}
	/* HTML5 display-role reset for older browsers */
	article, aside, details, figcaption, figure, 
	footer, header, hgroup, menu, nav, section {
		display: block;
	}
	body {
		line-height: 1;
	}
	ol, ul {
		list-style: none;
	}
	blockquote, q {
		quotes: none;
	}
	blockquote:before, blockquote:after,
	q:before, q:after {
		content: '';
		content: none;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

    thead{
        background-color: ${(p) => (p.theme.title === 'light' ? '#e0e0e0' : '#1d1d1d')}!important;
    }

	input{
		background-color: ${(p) => (p.theme.title === 'light' ? '#e0e0e0' : '#1d1d1d')};
		color: ${(p) => (p.theme.title === 'light' ? p.theme.colors.text : '#fff')};
		height: 38px;
		border-radius: 4px;
		border: 1px solid ${(props) => (props.className == 'error' ? 'red' : '#00000000')};
		padding: 0 10px;

		width: 100%;

		&:focus {
			border-color: ${(props) => (props.className == 'error' ? 'red' : 'rgb(142 179 240)')};
			box-shadow: 0px 0px 5px -2px
			${(props) => (props.className == 'error' ? 'red' : 'rgb(142 179 240)')};
		}
		:hover{
			border-color: ${(props) => (props.className == 'error' ? 'red' : 'rgb(142 179 240)')};
			box-shadow: 0px 0px 5px -2px
		}

        :read-only {
            background-color: ${(p) => (p.theme.title === 'light' ? '#939393' : '#3c3c3c')};
            color: ${(p) => (p.theme.title === 'light' ? '#ececec' : '#828282')};

            :focus {
                border-color: ${(p) => (p.theme.title === 'light' ? '#939393' : '#3c3c3c')};                
            }
            
            :hover{
                border-color: ${(p) => (p.theme.title === 'light' ? '#939393' : '#3c3c3c')};                
            }
        }
	}

    /* Change the white to any color */
	input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus, 
	input:-webkit-autofill:active
	{
        box-shadow: 0 0 0 35px ${(p) => (p.theme.title === 'light' ? '#e0e0e0' : '#1d1d1d')} inset ;
        
	}

	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus, 
	input:-webkit-autofill:active{
        border-color: ${(props) => (props.className == 'error' ? 'red' : 'rgb(142 179 240)')};

    }
	/*Change text in autofill textbox*/
	input:-webkit-autofill
	{
        border: 1px solid ${(props) => (props.className == 'error' ? 'red' : '#00000000')};

		-webkit-text-fill-color: ${(p) => (p.theme.title === 'light' ? p.theme.colors.text : '#fff')}!important;
	}

    textarea{
        background-color: ${(p) => (p.theme.title === 'light' ? '#e0e0e0' : '#1d1d1d')};
		color: ${(p) => (p.theme.title === 'light' ? p.theme.colors.text : '#fff')};
        border: 1px solid ${(props) => (props.className == 'error' ? 'red' : '#00000000')};
		padding: 0 10px;
        border-radius: 4px;

        &:focus {
			border-color: ${(props) => (props.className == 'error' ? 'red' : 'rgb(142 179 240)')};
			box-shadow: 0px 0px 5px -2px
			${(props) => (props.className == 'error' ? 'red' : 'rgb(142 179 240)')};
		}
		:hover{            
			border-color: ${(props) => (props.className == 'error' ? 'red' : 'rgb(142 179 240)')};
			box-shadow: 0px 0px 5px -2px;
		}
    }

	select{
		background-color: ${(p) => (p.theme.title === 'light' ? '#e0e0e0' : '#1d1d1d')};
		color: ${(p) => (p.theme.title === 'light' ? p.theme.colors.text : '#fff')};
		height: 38px;
		border-radius: 4px;
		border: 1px solid ${(props) => (props.className == 'error' ? 'red' : '#00000000')};
		padding: 0 10px;
		width: 100%;

        :hover{            
			border-color: ${(props) => (props.className == 'error' ? 'red' : 'rgb(142 179 240)')};
			box-shadow: 0px 0px 5px -2px;
		}
		&:focus {
			border-color: ${(props) => (props.className == 'error' ? 'red' : 'rgb(142 179 240)')};
			box-shadow: 0px 0px 5px -2px
			${(props) => (props.className == 'error' ? 'red' : 'rgb(142 179 240)')};
		}
	}
	/* Change the white to any color */
	input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus, 
	input:-webkit-autofill:active
	{
		border-color: ${(props) => (props.className == 'error' ? 'red' : 'rgb(142 179 240)')};
		

	}
	/*Change text in autofill textbox*/
	input:-webkit-autofill
	{
		-webkit-text-fill-color: ${(p) => (p.theme.title === 'light' ? p.theme.colors.text : '#fff')}!important;
	}
	
	select{
		background-color: ${(p) => (p.theme.title === 'light' ? '#e0e0e0' : '#1d1d1d')};
		color: ${(p) => (p.theme.title === 'light' ? p.theme.colors.text : '#fff')};
	}

	.TitleCategoria{
		font-size: 30px;
		color: ${(p) => p.theme.colors.text};
	}
	.LinkCategoria{
		font-size: 20px;
		color: ${(p) => p.theme.colors.text};
	}

	/* width */
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: ${(p) => (p.theme.title === 'light' ? p.theme.colors.background : '#33312b')}; 
	}
	
	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #888; 
		border-radius: 5px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #555; 
	}

	
	.backgroundRainbow{
		position: relative;  
	}

	.backgroundRainbow::before {
		overflow: hidden;
		background: url("https://static.vecteezy.com/system/resources/previews/001/218/845/non_2x/colorful-holographic-gradient-background-design-vector.jpg") center center;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		background-attachment: fixed;
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: -1;
		filter: ${(p) => (p.theme.title === 'light' ? 'brightness(0.9) blur(49px) opacity(.3);' : 'brightness(0.2) blur(2px); ')}; 
	
	}

	.autorImg{
		border: 2px solid #2882ce;
		height: 35px; 
		width: 35px; 
		border-Radius: 50%; 
		object-Fit: cover; 
		object-Position: top;
	}
	@keyframes scrollAnimationDireita {
		from { transform: skew(0deg,0deg) }
		50%  { transform: skew(0deg,1deg) } 
		to   { transform: skew(0deg,0deg) }
    }
	@keyframes scrollAnimationEsquerda {
		from { transform: skew(0deg,0deg) }
		50%  { transform: skew(0deg,-1deg) } 
		to   { transform: skew(0deg,0deg) }
    }

	.startAnimacao{
		>div{
			animation-play-state: running;		
		}
	}

	.animacao-direita{
		
		animation-name: scrollAnimationDireita;
		animation-duration: 0.6s;
		animation-play-state: paused;

	}
	.animacao-esquerda{
		
		animation-name: scrollAnimationEsquerda;
		animation-duration: 0.6s;
		animation-play-state: paused;
	}
	div{
		flex-shrink: 0;
	}
	
	.react-datepicker-wrapper{
		width:100%;
	}

	.toastClassCustom>div>.Toastify__toast-body{
		width:calc(100% - 15px);
	}

	.Toastify__toast--default{
		color: #333;
		font-weight:500;
	}

	.datePikerCustom{
		display: inline-flex;
		height: 38px;
		border-radius: 4px;
		padding: 0 10px;
		margin: 5px 5px;
		width: 100%;
		background-color: ${(p) => (p.theme.title === 'light' ? '#e0e0e0' : '#1d1d1d')};
		color: ${(p) => (p.theme.title === 'light' ? '#333' : '#fff')};

		>div{ 
			border: none;
		}
	}

	.pulse {
		animation: pulse 0.7s infinite;
		animation-direction: alternate;
		-webkit-animation-name: pulse;
		animation-name: pulse;
	}	

	@keyframes pulse {
		0% {
			transform: scale(1);
			filter: brightness(100%);
		}
		100% {
			transform: scale(1.05);
			filter: brightness(110%);
		}
	}
	.selected{
			background: ${(p) => lighten(0.03, p.theme.colors.background)}!important;
			color: #1463a6!important;
	}
	.tui-full-calendar-time-schedule{
		background-color:${(p) => p.theme.colors.colorBackGroundScheduleItem}!important;
	}
	.tui-full-calendar-time-schedule-content{
		border-color:${(p) => p.theme.colors.colorBackGroundScheduleItem}!important;
	}
	.tui-full-calendar-timegrid-timezone{
		background-color:${(p) => p.theme.colors.colorBackGroundSchedule}!important;
	}
`;
export const DivRouter = styled.div`
    height: 100%;
    width: calc(100% - 180px);
    display: flex;
    flex-direction: column;
    flex: 1;

    @media (max-width: 1300px) {
        margin: 0 0 0 40px;
    }
`;
