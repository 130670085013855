import React,{useState,useEffect,useRef,createRef} from 'react';
import { Button, Input, TextArea } from '~/components/commonComponents/commonComponents';
import TabelaModelo from '~/components/tabelaModelo/tabelaModelo';
import useApi from '~/service/api';
import { DivCampoFormulario, DivMainNotificacoes, DivTituloPagina } from './styledNotificacoes';
import PopupDefault from '~/components/popupDefault/PopupDefault';
import dayjs from 'dayjs';
import { useTheme } from 'styled-components';
import { toast } from 'react-toastify';
import { FormNotificacoes } from './formNotificacoes';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import FormAddImagem from './formAddimagem';

export default function Notificacoes() {
    const [listaNotificacoes, setListaNotificacoes] = useState([]);
    const [openPopupNovaNotificacao, setOpenPopupNovaNotificacao] = useState(false);
    const [openPopupAdicionaImagem, setOpenPopupAdicionaImagem] = useState(false)
    const [dadosNotificacao, setDadosNotificacao] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState('none');
    const headerTabelaNotificacoes = ['Id', 'Notificação', 'Validade', 'Permanente', 'Status', 'IddoEvento', 'Url'];
    const api = useApi();

    useEffect(()=>{
        getListaNotificacoes();
        
    },[])
    

    function abrirNovaNotificacao(){
        setOpenPopupNovaNotificacao(true)        
    }

    async function getListaNotificacoes(){
        setLoading('flex');
        try {
            const {status,data}= await api.get('/eventos/GetNotificacoesEventos')
            if(status != 200)
                throw 'Erro ao carregar lista de notificações.'
            setListaNotificacoes(data)
            
        } catch (error) {
            console.error(error);
        }
        setLoading('none');
    };
    
   
    function editaNotificacao(value){
        setOpenPopupNovaNotificacao(true);
        setDadosNotificacao(value);        
        setIsEdit(true);

    };

    function retornaValor(key, ObjCompleto) {
        if(String(key).toLowerCase() == "validade"){
            return dayjs(ObjCompleto[key]).format("DD/MM/YYYY")
        }
        if(String(key).toLowerCase() == "notificação"){
            return ObjCompleto["Notificacao"]
        }
        return ObjCompleto[key]
                
    };

    function fecharPopup(){
        setDadosNotificacao(null)
        setOpenPopupNovaNotificacao(false);
        setIsEdit(false)
    }

    
    return <DivMainNotificacoes>
        <DivTituloPagina >
            <h1>Lista de notificações</h1>
        </DivTituloPagina>
        <div style={{flex:1,overflow:'hidden auto'}}>
            <TabelaModelo
                header={headerTabelaNotificacoes}
                fnRetornaValor={retornaValor}
                clientes={listaNotificacoes}
                callbackDoubleClick={editaNotificacao}
            />
        </div>
        <div style={{height:'60px',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Button onClick={()=>abrirNovaNotificacao()}>Adicionar notificação</Button>
            <Button onClick={()=>setOpenPopupAdicionaImagem(true)}>Adicionar imagem</Button>
        </div>
         {/* ['Id', 'Notificação', 'Validade', 'Permanente', 'Status', 'IddoEvento', 'Url'] */}
        <PopupDefault                         
            open={openPopupNovaNotificacao} 
            close={()=>fecharPopup()}
            largura={'90%'}
            altura={'90%'}
            children={
                <FormNotificacoes dadosNotificacao={dadosNotificacao} 
                callbackCancelar={()=>fecharPopup()} isEdit={isEdit}
                atualizaTela={getListaNotificacoes} close={()=>fecharPopup()}
            />}
        />        
        <PopupDefault                         
            open={openPopupAdicionaImagem} 
            close={()=>setOpenPopupAdicionaImagem(false)}            
            children={
                <FormAddImagem             
                close={()=>setOpenPopupAdicionaImagem(false)}
            />}
        />        
        <LoadingScreen display={loading} />
    </DivMainNotificacoes>;
}