import React,{useState,useEffect,useRef} from 'react';
import {Button, FormControl,TextField,Box} from '@mui/material';
import { Title } from '../commonComponents/commonComponents';
import { Select as BaseSelect, selectClasses  } from '@mui/base/Select';
import { Option } from '@mui/base/Option';
import { Popper as BasePopper } from '@mui/base/Popper';
import { styled } from '@mui/system';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import useApi from '~/service/api';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import {cpf,cnpj} from 'cpf-cnpj-validator'
import LoadingScreen from '../loadScreen/Loadscreen';
import { DivMainFormCadastroCliente } from './styledFormCadastroCliente';

export default function FormCadastroCliente({close}) {
    const theme = useTheme();
    const apiSkip = useApi({skip:true});
    const api = useApi();
    const [listaEstados] = useState([{value:"", estado:"Selecione o estado"},{value:"AC", estado:"Acre"},
    {value:"AL", estado:"Alagoas"},
    {value:"AP", estado:"Amapá"},
    {value:"AM", estado:"Amazonas"},
    {value:"BA", estado:"Bahia"},
    {value:"CE", estado:"Ceará"},
    {value:"DF", estado:"Distrito Federal"},
    {value:"ES", estado:"Espírito Santo"},
    {value:"GO", estado:"Goiás"},
    {value:"MA", estado:"Maranhão"},
    {value:"MT", estado:"Mato Grosso"},
    {value:"MS", estado:"Mato Grosso do Sul"},
    {value:"MG", estado:"Minas Gerais"},
    {value:"PA", estado:"Pará"},
    {value:"PB", estado:"Paraíba"},
    {value:"PR", estado:"Paraná"},
    {value:"PE", estado:"Pernambuco"},
    {value:"PI", estado:"Piauí"},
    {value:"RJ", estado:"Rio de Janeiro"},
    {value:"RN", estado:"Rio Grande do Norte"},
    {value:"RS", estado:"Rio Grande do Sul"},
    {value:"RO", estado:"Rondônia"},
    {value:"RR", estado:"Roraima"},
    {value:"SC", estado:"Santa Catarina"},
    {value:"SP", estado:"São Paulo"},
    {value:"SE", estado:"Sergipe"},
    {value:"TO", estado:"Tocantins"}]);    
    const [dadosCliente, setDadosCliente] = useState({
        Nome:'',
        Email:'',
        DDD:'',
        Telefone:'',
        DDD2:'',
        Celular:'',
        Cgc:'',
        Endereco:'',
        Numero:'',
        Complemento:'',
        Bairro:'',
        Cidade:'',
        Estado:'',
        Cep:'',
        CodigoMunicipio:'',
        Observacao:''        
    });        
    const [loadScreen, setLoadScreen] = useState('none');
    const styleInputProps = {color:theme.colors.text,boxShadow:'none'}
    async function getInfoFromCep(cep){
        try {            
            if(dadosCliente.Endereco == ''){
                const responseGetCep = await apiSkip.get(`https://viacep.com.br/ws/${cep}/json/`)
                if(responseGetCep.status == 200){
                    const {data} = responseGetCep;
                    const {logradouro,complemento,bairro,localidade,ibge,uf} = data;
                    setDadosCliente({
                        ...dadosCliente,
                        Endereco:logradouro,
                        Complemento:complemento,
                        Bairro:bairro,
                        Cidade:localidade,
                        CodigoMunicipio:ibge,
                        Estado:uf
                    })                               
                }
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar informações do CEP.')
        }
    }    
    async function cadastrarNovoCliente() {
        try {
            
            const constValidaCgc = validaCgc(dadosCliente.Cgc);
            if(!constValidaCgc){
                toast.warning('CPF ou CNPJ inválidos.');                
                return;
            }
            const isCnpj = cnpj.isValid(dadosCliente.Cgc);

            if(isCnpj){
                const alerts = [];
                if(dadosCliente.Cep == ''){
                    alerts.push('Cep');
                }
                if(dadosCliente.Cidade == ''){
                    alerts.push('Cidade')
                }
                if(dadosCliente.Estado == ''){
                    alerts.push('Estado')
                }
                if(dadosCliente.Endereco == ''){
                    alerts.push('Endereço')
                }
                if(dadosCliente.Numero == ''){
                    alerts.push('Numero Endereço')
                }
                if(dadosCliente.Complemento == ''){
                    alerts.push('Complemento Endereço')
                }
                if(dadosCliente.Bairro == ''){
                    alerts.push('Bairro')
                }
                if(dadosCliente.CodigoMunicipio == ''){
                    alerts.push('Codigo Município')
                }
                if(alerts.length > 0){
                    toast.warning(`Cadastro de cliente com CNPJ exige as seguintes informações:\n
                    ${alerts.map(alert => `${alert}\n`)}`)
                    return;
                }
            }
            setLoadScreen('flex');
            const responseCadastrarNovoCliente = await api.post(`cliente/InsertNovoCliente`, dadosCliente);
            if (responseCadastrarNovoCliente.status == 200) {                
                setDadosCliente({
                    Nome: '',
                    Email: '',
                    DDD: '',
                    Telefone: '',
                    DDD2: '',
                    Celular: '',
                    Cgc: '',
                    Endereco: '',
                    Numero: '',
                    Complemento: '',
                    Bairro: '',
                    Cidade: '',
                    Estado: '',
                    Cep: '',
                    CodigoMunicipio: '',
                    Observacoes: '',
                });
                toast.success('Cliente cadastrado com sucesso.');
                close(false);
                setLoadScreen('none');
            } else {
                toast.error('Não foi possível cadastrar o cliente.');
                setLoadScreen('none');
            }
        } catch (error) {
            toast.error(error || 'Erro ao realizar solicitação');
            setLoadScreen('none');
        }
    }
    function setValuDadosCliente(value,name){
        setDadosCliente(OldValue => {
            return {...OldValue,[name]:value}
        })
    }

    function validaCgc(cgc){
        const isCnpj = cnpj.isValid(cgc);
        const isCpf = cpf.isValid(cgc);
        if(isCnpj || isCpf){
            return true
        }else{
            return false            
        }
    }

    const Popper = styled(BasePopper)`
    z-index: 9999;
  `;

    const blue = {
    100: '#DAECFF',
    200: '#99CCF3',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
    900: '#003A75',
    };
  
    const grey = {
        50: '#F3F6F9',
        100: '#E5EAF2',
        200: '#DAE2ED',
        300: '#C7D0DD',
        400: '#B0B8C4',
        500: '#9DA8B7',
        600: '#6B7A90',
        700: '#434D5B',
        800: '#303740',
        900: '#1C2025',
    };

    const StyledButton = styled('button', { shouldForwardProp: () => true })(
        ({ theme }) => `
        position: relative;
        font-family: IBM Plex Sans, sans-serif;
        font-size: 0.875rem;
        box-sizing: border-box;
        min-width: 320px;
        padding: 8px 12px;
        border-radius: 8px;
        text-align: left;
        line-height: 1.5;
        background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
        border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
        box-shadow: 0px 2px 4px ${
        theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
        };
    
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 120ms;
    
        &:hover {
        background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
        border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
        }
    
        &.${selectClasses.focusVisible} {
        outline: 0;
        border-color: ${blue[400]};
        box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[700] : blue[200]};
        }
    
        & > svg {
        font-size: 1rem;
        position: absolute;
        height: 100%;
        top: 0;
        right: 10px;
        }
        `,
    );
  
    const Listbox = styled('ul')(
    ({ theme }) => `    
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 6px;
    margin: 12px 0;
    min-width: 320px;
    border-radius: 12px;
    overflow: auto;
    outline: 0px;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    box-shadow: 0px 2px 4px ${
      theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
    };
    `,
    );

    const CustomButton = React.forwardRef(function CustomButton(props, ref) {
    const { ownerState, ...other } = props;
    return (
      <StyledButton type="button" {...other} ref={ref}>
        {other.children}
        <UnfoldMoreRoundedIcon />
      </StyledButton>
    );
    });

    const Select = React.forwardRef(function Select(props, ref) {
    const slots = {
        root: CustomButton,
        listbox: Listbox,
        popper: Popper,
        ...props.slots,
    };        
    return <BaseSelect {...props} ref={ref} slots={slots} />;
    });
  return (
    <DivMainFormCadastroCliente>
        <Box component={'form'} onSubmit={(event)=>{event.preventDefault();cadastrarNovoCliente()}} padding={'16px'} >
            <Title style={{textAlign:'center'}}>Cadastro novo cliente</Title>
            <TextField focused inputProps={{style:{color:theme.colors.text}}} sx={{marginBottom:2,marginTop:2}} 
                placeholder='Informe o nome do cliente'
                variant='outlined' label={'Nome do cliente'} fullWidth required name='Nome'
                value={dadosCliente.Nome}
                onChange={(event)=>setValuDadosCliente(event.target.value,event.target.name)}
            />
            <TextField focused inputMode='email' inputProps={{style:{color:theme.colors.text}}} sx={{marginBottom:2}} 
                placeholder='Informe o email do cliente'
                variant='outlined' label={'Email de assinante'} fullWidth required name='Email'
                onChange={(event)=>setValuDadosCliente(event.target.value,event.target.name)}
            />
            <Box component={'div'} display={'flex'} mb={2}>
                <Box component={'div'} display={'flex'} flexDirection={'column'} flex={1} mr={4}>
                    <Box component={'div'} mb={2} display={'flex'}>
                        <TextField focused inputProps={{ maxLength: 2,style:{...styleInputProps} }} 
                            variant='outlined' label={'DDD'} name='DDD' value={dadosCliente.DDD} 
                            onChange={(event)=>setValuDadosCliente(event.target.value.replace(/\D/g, ''),event.target.name)}
                        />
                        <TextField focused inputProps={{ style:{...styleInputProps}, maxLength: 20 }} variant='outlined' label={'Telefone'} sx={{flex:1,marginLeft:2}} name='Telefone'
                            value={dadosCliente.Telefone} onChange={(event)=>setValuDadosCliente(event.target.value.replace(/\D/g, ''),event.target.name)}
                        />
                    </Box>
                    <Box component={'div'} mb={2} display={'flex'}>
                        <TextField focused  inputProps={{ maxLength: 2,style:{...styleInputProps} }} variant='outlined' label={'DDD2'} name='DDD2'
                            value={dadosCliente.DDD2} onChange={(event)=>setValuDadosCliente(event.target.value.replace(/\D/g, ''),event.target.name)}
                        />
                        <TextField focused inputProps={{ maxLength: 20, style:{...styleInputProps} }}variant='outlined' label={'Celular'}required sx={{flex:1,marginLeft:2}} name='Celular'
                            value={dadosCliente.Celular} onChange={(event)=>setValuDadosCliente(event.target.value.replace(/\D/g, ''),event.target.name)}
                        />
                    </Box>
                    <TextField focused inputProps={{ maxLength: 14,style:{...styleInputProps} }} variant='outlined' label={'CGC'} fullWidth required sx={{marginBottom:2}} name='Cgc'
                        value={dadosCliente.Cgc} onChange={(event)=>setValuDadosCliente(event.target.value.replace(/\D/g, ''),event.target.name)}                    
                    />
                    <TextField focused inputProps={{ maxLength: 9,style:{...styleInputProps} }} sx={{mb:2}} variant='outlined' label={'CEP'} placeholder='Informe o cep do cliente' fullWidth name='Cep' 
                        value={dadosCliente.Cep} onChange={(event)=>setValuDadosCliente(event.target.value.replace(/\D/g, ''),event.target.name)}
                        onBlur={(event)=>getInfoFromCep(event.target.value)}
                    />
                    <TextField focused inputProps={{ maxLength: 20,style:{...styleInputProps} }} variant='outlined' label={'Cidade'} fullWidth sx={{marginBottom:2}}  name='Cidade' 
                        value={dadosCliente.Cidade} onChange={(event)=>setValuDadosCliente(event.target.value,event.target.name)}
                    />                
                    <FormControl required>
                        <Select sx={{height:'60px'}} native value={dadosCliente.Estado} name='Estado'>
                            {
                                listaEstados.map(estado =>(
                                    <Option slotProps={{height:40}} value={estado.value}>{estado.estado}</Option>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Box component={'div'} display={'flex'} flexDirection={'column'} flex={1}>
                    <TextField focused inputProps={{ maxLength: 50,style:{...styleInputProps} }} sx={{mb:2}} variant='outlined' label={'Endereço'} 
                        placeholder='Informe o endereço do cliente' fullWidth name='Endereco' 
                        value={dadosCliente.Endereco} onChange={(event)=>setValuDadosCliente(event.target.value,event.target.name)}
                    />
                    <TextField focused inputProps={{ maxLength: 50,style:{...styleInputProps} }} sx={{mb:2}} variant='outlined' label={'N°'} placeholder='Informe o n° do' fullWidth name='Numero'
                        value={dadosCliente.Numero}  onChange={(event)=>setValuDadosCliente(event.target.value,event.target.name)}
                    />
                    <TextField focused inputProps={{ maxLength: 25,style:{...styleInputProps} }} sx={{mb:2}} variant='outlined' label={'Complemento'} placeholder='Complemento do endereço' fullWidth name='Complemento'
                        value={dadosCliente.Complemento}  onChange={(event)=>setValuDadosCliente(event.target.value,event.target.name)}
                    />
                    <TextField focused inputProps={{ maxLength: 20,style:{...styleInputProps} }} sx={{mb:2}} variant='outlined' label={'Bairro'} placeholder='Informe o bairro do cliente' fullWidth name='Bairro'
                        value={dadosCliente.Bairro}  onChange={(event)=>setValuDadosCliente(event.target.value,event.target.name)}
                    />
                    <TextField focused inputProps={{style:{...styleInputProps}}} sx={{mb:2}} variant='outlined' label={'Código Município'} placeholder='Informe o código do município do cliente' fullWidth name='CodigoMunicipio'
                        value={dadosCliente.CodigoMunicipio}  onChange={(event)=>setValuDadosCliente(event.target.value,event.target.name)}
                    />                
                </Box>
            </Box>
            <TextField focused multiline rows={4} label={'Observações'} fullWidth inputProps={{style:{...styleInputProps}}} name='Observacao'
                value={dadosCliente.Observacao}  onChange={(event)=>setValuDadosCliente(event.target.value,event.target.name)}
            />
            <Box component={'div'} mt={2} display={'flex'}>
                <Button fullWidth type='button' variant='contained' sx={{mr:2,background:theme.colors.colorDelete}} onClick={()=>close(false)} >Cancelar</Button>
                <Button fullWidth type='submit' variant='contained' sx={{background:theme.colors.colorSave}}>Salvar</Button>
            </Box>
            <LoadingScreen display={loadScreen} />
        </Box>
    </DivMainFormCadastroCliente>
  );
}